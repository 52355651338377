import React from 'react';
import { Link } from 'gatsby';

import './taglist.less';

const Taglist = ({ tags, noHeader, noHash }) => {
  const renderTag = (tag) => {
    const tagLink = `/tagi/${tag}`;
    const tagName = noHash ? tag : `#${tag}`;
    return (
      <li key={tag}>
        <Link to={tagLink}>{tagName}</Link>
      </li>
    );
  };

  return (
    <section id="taglist" className="no-print">
      <ul>
        {!noHeader && <li className="label">tagi:</li>}
        {tags.map(renderTag)}
      </ul>
    </section>
  );
};

export default Taglist;
