import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/menu';
import Taglist from '../components/taglist';

const categories = {
  meals: ['śniadanie', 'obiad', 'kolacja', 'randka'],
  cuisine: ['kuchniapolska', 'kuchniawłoska', 'smakiazji', 'texmex', 'kuchniahiszpańska', 'kuchniatajska', 'kuchniagrecka'],
  difficulty: ['łatwe', 'średnie'],
  desserts: ['ciasto'],
  meatingredient: ['wołowina', 'salami', 'indyk', 'szarpanawołowina', 'kurczak'],
  otheringredients: ['łosoś', 'krewetki', 'jajka', 'tortilla', 'twaróg', 'szpinak', 'trufle', 'parmezan', 'feta', 'mimolette', 'cukinia', 'cieciorka', 'mule'],
  drinks: ['kawa'],
};

const CategoriesPage = () => (
  <Layout>
    <SEO title="Kategorie" />
    <header>
      <Menu></Menu>
    </header>
    <section className="page-content">
      <h1 className="section-header">Kategorie</h1>
      <h2>Posiłek</h2>
      <Taglist tags={categories.meals} noHeader noHash />
      <h2>Kuchnie świata</h2>
      <Taglist tags={categories.cuisine} noHeader noHash />
      <h2>Poziom trudności</h2>
      <Taglist tags={categories.difficulty} noHeader noHash />
      <h2>Składnik mięsny</h2>
      <Taglist tags={categories.meatingredient} noHeader noHash />
      <h2>Inne składniki</h2>
      <Taglist tags={categories.otheringredients} noHeader noHash />
      <h2>Desery</h2>
      <Taglist tags={categories.desserts} noHeader noHash />
      <h2>Napoje</h2>
      <Taglist tags={categories.drinks} noHeader noHash />
    </section>
  </Layout>
);

export default CategoriesPage;
